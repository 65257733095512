import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Resource } from '../../../core/abstract/resource';
import { ObjectLiteral } from '../../../core/interfaces/object-literal';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { ShiftsService } from '../../../core/services/resources/shifts.service';

import { OrderIngredient } from './order-ingredient.model';
import { OrderStatus } from './order-status.enum';
import { Order } from './order.model';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends Resource<Order> {
  constructor(
    protected http: HttpClient,
    private cachedDataService: CachedDataService,
    private shiftsService: ShiftsService,
  ) {
    super(http, {
      path: '/orders',
    });
  }

  find(options: ObjectLiteral = {}): Observable<Order[]> {
    options = {
      shopId: this.cachedDataService.getShopId(),
      ...options,
    };

    if (options.providerId == null) {
      Reflect.deleteProperty(options, 'providerId');
    }

    return super.find(options).pipe(
      map((orders) => orders.map((order) => this.transformFromBackend(order))),
      map((orders) => orderBy(orders, 'orderFor', 'desc')),
    );
  }

  findForImport(
    source: 'refills' | 'sales',
    options: ObjectLiteral = {},
  ): Observable<OrderIngredient[]> {
    options = {
      shopId: this.cachedDataService.getShopId(),
      ...options,
    };

    if (options.providerId == null) {
      Reflect.deleteProperty(options, 'providerId');
    }

    return this.http.get<OrderIngredient[]>(`${this.path}/import/${source}`, {
      params: options,
    });
  }

  get(id: number | string): Observable<Order> {
    return super.get(id).pipe(map((order) => this.transformFromBackend(order)));
  }

  create(order: Order): Observable<Order> {
    order.shopId = this.cachedDataService.getShopId();
    order.userId = this.cachedDataService.getUser().id;

    return super.create(this.transformToBackend(order));
  }

  update(id: number | string, body: Partial<Order>): Observable<Order> {
    return super.update(id, body);
  }

  private transformFromBackend(order: Order): Order {
    const orderClone = cloneDeep(order);

    orderClone.orderFor = new Date(orderClone.orderFor);
    orderClone.createdAt = new Date(orderClone.createdAt);
    orderClone.providerName = orderClone.provider?.name ?? '-';

    orderClone.typeName =
      orderClone.status === OrderStatus.Processed ? 'Опрацьоване' : 'Створене';

    orderClone.canView = orderClone.status === OrderStatus.Processed;
    orderClone.canCopy = true;
    orderClone.canPrint = false;
    orderClone.canPrintLabel = false;
    orderClone.canUpdate = !orderClone.canView;
    orderClone.canDelete = !orderClone.canView;

    return orderClone;
  }

  private transformToBackend(order: Order): Order {
    const orderClone = cloneDeep(order);

    orderClone.shiftId = this.shiftsService.getCurrentShiftId();

    return orderClone;
  }
}
