import { formatCurrency } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  IonInfiniteScroll,
  IonInput,
  IonSearchbar,
  ModalController,
} from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';

import { MAX_QUANTITY_VALUE } from '../core/constants/form-validations.const';
import { INGREDIENT_ROWS } from '../core/constants/visible-rows.const';
import { ShiftVerifiedStatus } from '../core/enum/shift-verified-status.enum';
import { IStorageUserSettings } from '../core/interfaces/storage-user-setting.interface';
import { IngredientCategory } from '../core/models/ingredient-category.model';
import { Ingredient } from '../core/models/ingredient.model';
import { ShiftIngredient } from '../core/models/shift-ingredient.model';
import { Shift } from '../core/models/shift.model';
import { Shop } from '../core/models/shop.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { HostListenerService } from '../core/services/host-listener.service';
import { IngredientsService } from '../core/services/resources/ingredients.service';
import { ShiftsService } from '../core/services/resources/shifts.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings/settings.const';
import { MultibarcodeDialog } from '../shared/components/multibarcode-dialog/multibarcode.dialog';

@Component({
  selector: 'bk-revisions',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss'],
})
export class RevisionComponent {
  @ViewChild(IonSearchbar) searchbar: IonSearchbar;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  shiftIngredients: ShiftIngredient[];
  originalShiftIngredients: ShiftIngredient[];
  ingredientCategories: IngredientCategory[] = [];

  allCategories: IngredientCategory = { id: 0, name: 'Всі', position: 0 };
  filterCategory: IngredientCategory;
  filterSearch = '';

  ingredients: Ingredient[];

  MIN_QUANTITY_VALUE = 0;
  MAX_QUANTITY_VALUE = MAX_QUANTITY_VALUE;

  visibleRows = INGREDIENT_ROWS;

  isBluetoothEnabled: boolean;
  userSettings: IStorageUserSettings;

  shop: Shop;
  shift: Shift | null;

  searchBarcodeMode = false;
  searchBarcode = '';

  hideRowsWithData = false;
  requestInProgress = false;

  constructor(
    private router: Router,
    private events: Events,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private cachedDataService: CachedDataService,
    private hostListenerService: HostListenerService,
    private shiftsService: ShiftsService,
    private ingredientsService: IngredientsService,
    private toastService: ToastService,
    private utilsService: UtilsService,
  ) {
    this.shop = this.cachedDataService.getShop();
    this.userSettings = this.cachedDataService.getUserSettings();

    this.events.subscribe('Barcode-revision', (barcode: string) => {
      if (this.searchBarcodeMode) {
        this.searchBarcode = barcode;
      } else {
        this.checkIngredientByBarcode(barcode);
      }
    });
  }

  ionViewWillEnter(): void {
    this.hostListenerService.setEventType('revision');

    this.refresh(false);
  }

  showBarcodeSearchbar(): void {
    this.searchBarcodeMode = !this.searchBarcodeMode;

    setTimeout(() => {
      this.searchbar.setFocus();
    }, 100);
  }

  clearBarcodeSearchbar(): void {
    this.searchBarcodeMode = !this.searchBarcodeMode;
    this.searchBarcode = '';
  }

  checkBarcode(): void {
    if (this.searchBarcode === '') {
      return;
    }

    const barcode = this.searchBarcode;

    this.searchBarcode = '';

    this.checkIngredientByBarcode(barcode);
  }

  async checkIngredientByBarcode(barcode: string): Promise<void> {
    const filteredShiftIngredients = this.shiftIngredients.filter((si) =>
      `;${si.ingredient.barcode ?? ''};`.includes(`;${barcode};`),
    );

    if (filteredShiftIngredients.length === 1) {
      this.updateIngredient(filteredShiftIngredients[0]);
    } else if (filteredShiftIngredients.length === 0) {
      this.toastService.presentNoBarcode(barcode);
    } else {
      const filteredIngredients = filteredShiftIngredients.map(
        (si) => si.ingredient,
      );

      const modal = await this.modalCtrl.create({
        component: MultibarcodeDialog,
        componentProps: {
          barcode,
          items: filteredIngredients,
        },
        backdropDismiss: false,
      });

      await modal.present();

      const { data } = await modal.onWillDismiss();

      if (!data.success) {
        return;
      }

      const shiftIngredient = filteredShiftIngredients.find(
        (si) => si.ingredient.id === data.ingredient.id,
      );

      if (shiftIngredient) {
        this.updateIngredient(shiftIngredient);
      }
    }
  }

  search(event: any): void {
    this.filterSearch = event.target.value || '';

    this.filter();
  }

  filter(): void {
    this.visibleRows = INGREDIENT_ROWS;
    this.shiftIngredients = cloneDeep(this.originalShiftIngredients);

    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = false;
    }

    if (this.filterSearch) {
      const searchedWords = this.filterSearch.trim().toLowerCase().split(' ');

      this.shiftIngredients = this.shiftIngredients.filter(
        (shiftIngredient) => {
          const name = shiftIngredient.ingredient.name.trim().toLowerCase();

          return searchedWords.every((word) => name.includes(word));
        },
      );
    }

    if (this.filterCategory.id !== this.allCategories.id) {
      this.shiftIngredients = this.shiftIngredients.filter(
        (shiftIngredient) =>
          shiftIngredient.ingredient.categoryId === this.filterCategory.id,
      );
    }

    if (this.hideRowsWithData) {
      this.shiftIngredients = this.shiftIngredients.filter(
        (shiftIngredient) => shiftIngredient.endRealQuantity == null,
      );
    }
  }

  showCategory(ingredient: Ingredient): void {
    if (!ingredient.category) {
      return;
    }

    this.toastService.present(ingredient.category.name);
  }

  selectAll(quantityInput: IonInput): void {
    quantityInput.getInputElement().then((data) => {
      data.select();
    });
  }

  async addQuantity(shiftIngredient: ShiftIngredient): Promise<void> {
    const inputFieldId = 'inputField';

    const alert = await this.alertCtrl.create({
      header: 'Редагування ревізії',
      message: `
        Введіть кількість, на величину якої буде збільшено залишок товару.<br><br>
        Наприклад, якщо поточне значення "3" (кг), а внести "4" (кг), то в підсумку за результатами ревізії залишок буде "7" (кг)`,
      inputs: [
        {
          id: inputFieldId,
          name: 'inputAmount',
          placeholder: `Кількість`,
          type: 'number',
          value: 0,
          min: 0,
          max: MAX_QUANTITY_VALUE,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Підтвердити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (data: { inputAmount: string }) => {
            this.updateEndRealQuantity(shiftIngredient, data.inputAmount);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
      backdropDismiss: false,
    });

    alert.addEventListener('ionAlertDidPresent', (e) => {
      const inputField = document.getElementById(inputFieldId);

      if (!inputField) {
        return;
      }

      setTimeout(() => {
        inputField.focus();

        if (inputField instanceof HTMLInputElement) {
          inputField.select();
        }
      }, 100);

      inputField.onkeyup = async (event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (inputField instanceof HTMLInputElement) {
          this.updateEndRealQuantity(shiftIngredient, inputField.value);

          await alert.dismiss();
        }
      };
    });

    await alert.present();
  }

  private async updateEndRealQuantity(
    shiftIngredient: ShiftIngredient,
    value: string,
  ): Promise<void> {
    if (!value) {
      return;
    }

    const inputAmount = Math.max(0, Number(value));

    if (!inputAmount) {
      return;
    }

    const originalShiftIngredient = this.originalShiftIngredients.find(
      (si) => si.ingredient.id === shiftIngredient.ingredient.id,
    );

    const updateShiftIngredient = new ShiftIngredient();

    updateShiftIngredient.id = shiftIngredient.id;
    updateShiftIngredient.shiftId = shiftIngredient.shiftId;
    updateShiftIngredient.endRealQuantity = inputAmount;

    this.shiftsService
      .updateCurrentShiftIngredient(shiftIngredient.id, updateShiftIngredient)
      .subscribe(
        (updatedShiftIngredient) => {
          shiftIngredient.endRealQuantity =
            updatedShiftIngredient.endRealQuantity;

          if (originalShiftIngredient != null) {
            originalShiftIngredient.endRealQuantity =
              updatedShiftIngredient.endRealQuantity;
          }

          this.toastService.present(
            'Оновлено',
            `${shiftIngredient.ingredient.name} на час ревізії: ${updatedShiftIngredient.endRealQuantity}`,
            1500,
            'top-toast',
          );
        },
        () => {
          shiftIngredient.endRealQuantity =
            originalShiftIngredient?.endRealQuantity ?? null;

          this.toastService.presentWarning(
            'Помилка збереження',
            shiftIngredient.ingredient.name,
            1500,
          );
        },
      );
  }

  realQuantityChanged(shiftIngredient: ShiftIngredient): void {
    const originalShiftIngredient = this.originalShiftIngredients.find(
      (si) => si.ingredient.id === shiftIngredient.ingredient.id,
    );

    const requestShiftIngredients: ShiftIngredient[] = [];

    requestShiftIngredients.push(shiftIngredient);

    this.shiftsService
      .updateCurrentShiftIngredients(requestShiftIngredients)
      .subscribe(
        () => {
          if (originalShiftIngredient) {
            originalShiftIngredient.startRealQuantity =
              shiftIngredient.startRealQuantity;
            originalShiftIngredient.endRealQuantity =
              shiftIngredient.endRealQuantity;
          }

          const startQuantity = shiftIngredient.startRealQuantity
            ? `на поч. зміни: ${shiftIngredient.startRealQuantity}`
            : '';

          const endQuantity = shiftIngredient.endRealQuantity
            ? `на ${!this.shop.dailyRevision ? 'час ревізії' : 'кін. зміни'}: ${
                shiftIngredient.endRealQuantity
              }`
            : '';

          let quantity = '';

          quantity += startQuantity ? `, ${startQuantity}` : '';
          quantity += endQuantity ? `, ${endQuantity}` : '';

          this.toastService.present(
            'Оновлено',
            `${shiftIngredient.ingredient.name}${quantity}`,
            1500,
            'top-toast',
          );
        },
        () => {
          shiftIngredient.startRealQuantity =
            originalShiftIngredient?.startRealQuantity ?? null;
          shiftIngredient.endRealQuantity =
            originalShiftIngredient?.endRealQuantity ?? null;

          this.toastService.presentWarning(
            'Помилка збереження',
            shiftIngredient.ingredient.name,
            1500,
          );
        },
      );
  }

  showMore(event: any): void {
    setTimeout(() => {
      this.visibleRows += INGREDIENT_ROWS;

      event.target.complete();

      if (this.shiftIngredients.length <= this.visibleRows) {
        event.target.disabled = true;
      }
    }, 300);
  }

  async create(): Promise<void> {
    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    const alert = await this.alertCtrl.create({
      header: 'Ревізія',
      message: `<strong>Згенерувати</strong> дані для проведення ревізії?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.requestInProgress = false;
          },
        },
        {
          text: 'Згенерувати',
          role: 'confirm',
          cssClass: 'primary',
          handler: () => {
            this.shiftsService.createRevision().subscribe(
              (shiftIngredients) => {
                this.processShiftIngredients(shiftIngredients);
              },
              (error) => {
                this.processError(error, 'згенерувати');
              },
              () => {
                this.requestInProgress = false;
              },
            );
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  refresh(useFilter: boolean = true): void {
    this.shiftsService.getShift().subscribe((shift) => {
      this.shift = shift;

      if (shift) {
        if (shift.verified !== ShiftVerifiedStatus.NoRevision) {
          this.loadData(useFilter);
        } else {
          this.processShiftIngredients([]);
        }
      }
    });
  }

  async openMenu(): Promise<void> {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    if (this.shift?.verified === ShiftVerifiedStatus.Unverified) {
      actionSheetButtons.push({
        text: 'Фінансові підсумки',
        icon: 'reader-outline',
        cssClass: 'totals',
        handler: () => {
          this.prepareTotals();
        },
      });

      actionSheetButtons.push({
        text: 'Заповнити нулями',
        icon: 'color-fill-outline',
        cssClass: 'fill',
        handler: () => {
          this.fillZero();
        },
      });

      actionSheetButtons.push({
        text: this.hideRowsWithData ? 'Показати всі' : 'Приховати заповнені',
        icon: this.hideRowsWithData ? 'star-outline' : 'star-half-outline',
        cssClass: 'hide',
        handler: () => {
          this.toggleHideRowsMode();
        },
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Додаткові операції',
      cssClass: 'revision-action-sheet-controller',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();
  }

  private async prepareTotals(): Promise<void> {
    if (this.ingredients) {
      await this.calcAndShowTotals();
    } else {
      this.ingredientsService
        .findForApp({
          needSalePrice: true,
        })
        .subscribe(
          async (ingredients) => {
            this.ingredients = ingredients;

            await this.calcAndShowTotals();
          },
          (error) => {
            const httpError = this.utilsService.getParsedError(error);
            const note = `Не вдалося отримати інформацію про ціни`;

            if (httpError.statusCode) {
              this.utilsService.showErrorAlert(httpError);
            } else {
              this.utilsService.showErrorToast(httpError, {
                note,
                isWarning: true,
              });
            }
          },
        );
    }
  }

  private async calcAndShowTotals(): Promise<void> {
    let costSum = 0;
    let saleSum = 0;

    for (const shiftIngredient of this.shiftIngredients) {
      if (!Boolean(shiftIngredient.endRealQuantity)) {
        continue;
      }

      const ingredient = this.ingredients.find(
        (i) => i.id === shiftIngredient.ingredient.id,
      );

      if (ingredient != null) {
        const quantity = +(shiftIngredient.endRealQuantity ?? 0);

        costSum += quantity * shiftIngredient.costPrice;
        saleSum += quantity * (shiftIngredient.salePrice ?? 0);
      }
    }

    let totalsInSalePrices = '';

    if (this.shop.backInSalePrice) {
      totalsInSalePrices = `<br><br>Вартість залишків у цінах продажу:<br>${formatCurrency(
        saleSum,
        'uk_UA',
        '₴',
      )}`;
    }

    const alert = await this.alertCtrl.create({
      header: 'Фінансові підсумки ревізії',
      message: `Вартість залишків:<br>${formatCurrency(
        costSum,
        'uk_UA',
        '₴',
      )}${totalsInSalePrices}`,
      buttons: [{ text: 'Закрити', role: 'close' }],
      backdropDismiss: false,
    });

    await alert.present();
  }

  private fillZero(): void {
    this.hideRowsWithData = false;

    this.shiftsService.fillRevisionByZero().subscribe(
      (shiftIngredients) => {
        this.processShiftIngredients(shiftIngredients);
      },
      (error) => {
        this.processError(error, 'завантажити');
      },
    );
  }

  private toggleHideRowsMode(): void {
    this.hideRowsWithData = !this.hideRowsWithData;

    this.filter();
  }

  private loadData(useFilter: boolean): void {
    this.shiftsService.getShiftIngredients().subscribe(
      (shiftIngredients) => {
        this.processShiftIngredients(shiftIngredients);

        if (useFilter) {
          this.filter();
        }
      },
      (error) => {
        this.processError(error, 'завантажити');
      },
    );
  }

  private processShiftIngredients(shiftIngredients: ShiftIngredient[]): void {
    this.filterCategory = this.allCategories;
    this.ingredientCategories = [];
    this.shiftIngredients = shiftIngredients;
    this.originalShiftIngredients = cloneDeep(shiftIngredients);

    this.ingredientCategories.push(this.allCategories);

    shiftIngredients.forEach((shiftIngredient) => {
      const category = this.ingredientCategories.find(
        (item) => item.id === shiftIngredient.ingredient.categoryId,
      );

      if (!category && shiftIngredient.ingredient.category) {
        this.ingredientCategories.push(shiftIngredient.ingredient.category);
      }
    });
  }

  private processError(error: any, operationName: string): void {
    const httpError = this.utilsService.getParsedError(error);
    const note = `Не вдалося ${operationName} дані ревізії`;

    if (httpError.statusCode) {
      this.utilsService.showErrorAlert(httpError);
    } else {
      this.utilsService.showErrorToast(httpError, {
        note,
        isWarning: true,
      });
    }

    this.router.navigateByUrl('/shop');
  }

  private updateIngredient(shiftIngredient: ShiftIngredient): void {
    this.shiftIngredients.splice(
      this.shiftIngredients.indexOf(shiftIngredient),
      1,
    );

    this.shiftIngredients.splice(0, 0, shiftIngredient);

    shiftIngredient.endRealQuantity =
      +(shiftIngredient.endRealQuantity ?? 0) + 1;

    this.realQuantityChanged(shiftIngredient);
  }
}
