import { formatNumber } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  IonItemSliding,
  Platform,
} from '@ionic/angular';
import remove from 'lodash-es/remove';
import round from 'lodash-es/round';

import { ONE_KOP } from '../../core/constants/form-validations.const';
import { Shift } from '../../core/models/shift.model';
import { Shop } from '../../core/models/shop.model';
import { CachedDataService } from '../../core/services/cached-data.service';
import { IngredientsService } from '../../core/services/resources/ingredients.service';
import { ShiftsService } from '../../core/services/resources/shifts.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { PRroZReport } from '../../p-rro/fsco/models/p-rro-z-report.model';
import { PRroService } from '../../p-rro/p-rro.service';
import { LabelPrinterService } from '../../settings/printer/label/label-printer.service';
import { ReceiptPrinterService } from '../../settings/printer/receipt/receipt-printer.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../../settings/settings.const';
import { Deposit } from '../../transactions/transaction/models/deposit.model';
import { Incasation } from '../../transactions/transaction/models/incasation.model';
import { Transaction } from '../../transactions/transaction/models/transaction.model';
import { TransactionsService } from '../../transactions/transactions.service';

import { Invoice } from './invoice-type.enum';
import { InvoicesGroup } from './invoices-group.model';
import { Move } from './move/move.model';
import { MovesService } from './move/moves.service';
import { Order } from './order/order.model';
import { OrdersService } from './order/orders.service';
import { Refill } from './refill/refill.model';
import { RefillsService } from './refill/refill.service';
import { Waste } from './waste/waste.model';
import { WastesService } from './waste/wastes.service';

type InvoiceDoc = Refill | Waste | Move | Order;

@Component({
  selector: 'bk-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  @ViewChild(IonItemSliding) itemSliding: IonItemSliding;

  invoicesGroups: InvoicesGroup[] = [];

  invoiceTitle: string;
  invoiceType: Invoice;
  invoiceEnum = Invoice;

  isNoData: boolean;
  isIOS: boolean;

  shop: Shop;
  shift: Shift;
  prroZReport: PRroZReport;
  isPRroActive = false;

  isPrintLabelMode = false;

  private usedComponent: string;

  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private wastesService: WastesService,
    private refillsService: RefillsService,
    private movesService: MovesService,
    private ordersService: OrdersService,
    private shiftsService: ShiftsService,
    private ingredientsService: IngredientsService,
    private receiptPrinterService: ReceiptPrinterService,
    private labelPrinterService: LabelPrinterService,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private platform: Platform,
    private cachedDataService: CachedDataService,
    private prroService: PRroService,
    private actionSheetCtrl: ActionSheetController,
    private transactionsService: TransactionsService,
  ) {
    this.shop = this.cachedDataService.getShop();

    this.isPRroActive = this.cachedDataService.isPRroActive();
    this.isIOS = this.platform.is('ios');
  }

  ngOnInit(): void {
    switch (this.router.url.replace('/', '')) {
      case Invoice.refill.toString():
        this.invoiceType = Invoice.refill;
        this.usedComponent = '/refill';
        this.invoiceTitle = 'надходження';

        break;

      case Invoice.return.toString():
        this.invoiceType = Invoice.return;
        this.usedComponent = '/return';
        this.invoiceTitle = 'повернення';

        break;

      case Invoice.waste.toString():
        this.invoiceType = Invoice.waste;
        this.usedComponent = '/waste';
        this.invoiceTitle = 'списання';

        break;

      case Invoice.move.toString():
        this.invoiceType = Invoice.move;
        this.usedComponent = '/move';
        this.invoiceTitle = 'переміщення';

        break;

      case Invoice.order.toString():
        this.invoiceType = Invoice.order;
        this.usedComponent = '/order';
        this.invoiceTitle = 'замовлення';

        break;
    }
  }

  ionViewWillEnter(): void {
    this.loadShiftData();
    this.loadPRroShiftData();

    const shift = this.cachedDataService.getShift();

    switch (this.invoiceType) {
      case Invoice.refill:
        this.initShiftRefill(shift);
        break;

      case Invoice.return:
        this.initShiftWasteOrReturn(shift);
        break;

      case Invoice.waste:
        this.initShiftWasteOrReturn(shift);
        break;

      case Invoice.move:
        this.initShiftMove(shift);
        break;

      case Invoice.order:
        this.initShiftOrder(shift);
        break;
    }

    this.labelPrinterService.status().then((status) => {
      this.isPrintLabelMode = status.isPrinterAvailable;
    });
  }

  async create(): Promise<void> {
    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();
      return;
    }

    this.router.navigateByUrl(`/${this.invoiceType}${this.usedComponent}`);
  }

  async update(invoice: InvoiceDoc): Promise<void> {
    this.itemSliding.close();

    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();
      return;
    }

    this.router.navigateByUrl(`/${this.invoiceType}${this.usedComponent}`, {
      state: {
        isEdit: true,
        invoice: JSON.stringify(invoice),
      },
    });
  }

  async view(invoice: InvoiceDoc): Promise<void> {
    this.itemSliding.close();

    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();
      return;
    }

    this.router.navigateByUrl(`/${this.invoiceType}${this.usedComponent}`, {
      state: {
        isView: true,
        invoice: JSON.stringify(invoice),
      },
    });
  }

  async copy(invoice: InvoiceDoc): Promise<void> {
    this.itemSliding.close();

    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();
      return;
    }

    this.router.navigateByUrl(`/${this.invoiceType}${this.usedComponent}`, {
      state: {
        isCopy: true,
        invoice: JSON.stringify(invoice),
      },
    });
  }

  async print(invoice: Refill | Waste | Move): Promise<void> {
    this.itemSliding.close();

    switch (this.invoiceType) {
      case Invoice.refill:
        await this.receiptPrinterService.printRefill(invoice as Refill, {
          viewMode: true,
        });

        break;

      case Invoice.return:
      case Invoice.waste:
        this.wasteOrReturnPrintDialog(invoice as Waste);
        break;

      case Invoice.move:
        await this.receiptPrinterService.printMove(invoice as Move, {
          viewMode: true,
        });

        break;

      case Invoice.order:
        break;
    }
  }

  async printLabel(invoice: Refill): Promise<void> {
    this.itemSliding.close();

    switch (this.invoiceType) {
      case Invoice.return:
      case Invoice.waste:
      case Invoice.move:
      case Invoice.order:
        break;

      case Invoice.refill:
        this.ingredientsService
          .findForApp({
            needSalePrice: true,
          })
          .subscribe(async (ingredients) => {
            invoice.refillIngredients.forEach((refillIngredient) => {
              const ingredient = ingredients.find(
                (i) => i.id === refillIngredient.ingredientId,
              );

              if (ingredient?.product != null) {
                refillIngredient.productId = ingredient.product.id;
              }
            });

            await this.labelPrinterService.printRefill(invoice);
          });

        break;
    }
  }

  private wasteOrReturnPrintDialog(invoice: Waste): void {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    actionSheetButtons.push({
      text: 'Накладна',
      icon: 'print-outline',
      cssClass: 'report',
      handler: () => {
        this.receiptPrinterService.printWaste(invoice, {
          viewMode: true,
          salePriceMode: false,
        });
      },
    });

    actionSheetButtons.push({
      text: 'Накладна з цінами продажу',
      icon: 'print-outline',
      cssClass: 'report',
      handler: async () => {
        await this.receiptPrinterService.printWaste(invoice, {
          viewMode: true,
          salePriceMode: true,
        });
      },
    });

    this.actionSheetCtrl
      .create({
        header: 'Додаткові операції',
        cssClass: 'sales-action-sheet-controller',
        buttons: actionSheetButtons,
      })
      .then((actionSheet) => actionSheet.present());
  }

  async delete(invoice: InvoiceDoc, group: InvoicesGroup): Promise<void> {
    this.itemSliding.close();

    if (
      this.invoiceType === Invoice.waste &&
      round(
        (invoice as Waste).receivedAmount - this.shift.totals.cashRemain,
        2,
      ) >= ONE_KOP
    ) {
      this.toastService.presentWarning(
        'Неправильно заповнені дані',
        'Недостатньо коштів у касі',
      );

      return;
    }

    const isOnline = await this.utilsService.isOnline();

    if (!isOnline) {
      this.toastService.presentNoInternet();
      return;
    }

    const note = this.getConfirmSubtitle(invoice);

    const alert = await this.alertCtrl.create({
      header: 'Видалення документа',
      message: `${note}<strong>Видалити</strong> накладну ${this.invoiceTitle}?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            const canDelete = await this.canDelete(invoice);

            if (!canDelete) {
              return;
            }

            this.deleteDoc(invoice, group);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  private async canDelete(invoice: InvoiceDoc): Promise<boolean> {
    let transaction: Transaction | null = null;

    let operationSum = 0;
    let provider = '';
    let comment = '';

    if (this.invoiceType === Invoice.return) {
      operationSum = -1 * (invoice as Waste).receivedAmount;
      provider = ` від ${(invoice as Waste).provider?.name ?? '-'}`;
      comment = `Видалення накладної повернення${provider}`;
    } else if (this.invoiceType === Invoice.refill) {
      operationSum = (invoice as Refill).paidAmount;
      provider = ` від ${(invoice as Refill).provider?.name ?? '-'}`;
      comment = `Видалення накладної надходження${provider}`;
    }

    if (Math.abs(operationSum) > 0) {
      transaction =
        operationSum < 0
          ? new Incasation({
              comment,
              amount: Math.abs(operationSum),
            })
          : new Deposit({
              comment,
              amount: Math.abs(operationSum),
            });

      transaction = await this.transactionsService.create(transaction);

      return transaction != null;
    }

    return true;
  }

  private deleteDoc(invoice: InvoiceDoc, group: InvoicesGroup): void {
    switch (this.invoiceType) {
      case Invoice.refill:
        this.deleteRefill(invoice as Refill, group);
        break;

      case Invoice.return:
      case Invoice.waste:
        this.deleteWaste(invoice as Waste, group);
        break;

      case Invoice.move:
        this.deleteMove(invoice as Move, group);
        break;

      case Invoice.order:
        this.deleteOrder(invoice as Order, group);
        break;
    }
  }
  private async deleteRefill(
    refill: Refill,
    group: InvoicesGroup,
  ): Promise<void> {
    this.refillsService.delete(refill.id).subscribe(() => {
      remove(group.invoices as Refill[], (invoice) => invoice.id === refill.id);

      this.toastService.present(`Накладну надходження видалено`);
    });
  }

  private async deleteWaste(waste: Waste, group: InvoicesGroup): Promise<void> {
    this.wastesService.delete(waste.id).subscribe(() => {
      remove(group.invoices as Waste[], (invoice) => invoice.id === waste.id);

      this.toastService.present(
        `Накладну ${waste.back ? 'повернення' : 'списання'} видалено`,
      );
    });
  }

  private deleteMove(move: Move, group: InvoicesGroup): void {
    this.movesService.delete(move.id).subscribe(() => {
      remove(group.invoices as Move[], (invoice) => invoice.id === move.id);

      this.toastService.present('Накладну переміщення видалено');
    });
  }

  private deleteOrder(order: Order, group: InvoicesGroup): void {
    this.ordersService.delete(order.id).subscribe(() => {
      remove(group.invoices as Order[], (invoice) => invoice.id === order.id);

      this.toastService.present('Накладну замовлення видалено');
    });
  }

  private initShiftRefill(shift: Shift): void {
    this.invoicesGroups = [];

    this.refillsService
      .find({ shiftId: shift.id, shopId: shift.shopId, updatedView: true })
      .subscribe((refills) => {
        this.createGroups(refills);
        this.groupInvoices(refills);
      });
  }

  private initShiftWasteOrReturn(shift: Shift): void {
    this.invoicesGroups = [];

    this.wastesService
      .find({
        shiftId: shift.id,
        shopId: shift.shopId,
        updatedView: true,
        back: this.invoiceType === Invoice.return,
      })
      .subscribe((docs) => {
        const docsByType = docs.filter(
          (doc) => doc.back === (this.invoiceType === Invoice.return),
        );

        this.createGroups(docsByType);
        this.groupInvoices(docsByType);
      });
  }

  private initShiftMove(shift: Shift): void {
    this.invoicesGroups = [];

    this.movesService.findForApp({ shiftId: shift.id }).subscribe((moves) => {
      this.createGroups(moves);
      this.groupInvoices(moves);
    });
  }

  private initShiftOrder(shift: Shift): void {
    this.invoicesGroups = [];

    this.ordersService.find({ shiftId: shift.id }).subscribe((orders) => {
      this.createGroups(orders);
      this.groupInvoices(orders);
    });
  }

  private createGroups(invoices: InvoiceDoc[]): void {
    this.isNoData = invoices.length === 0;

    invoices.forEach((invoice) => {
      if (
        this.invoicesGroups.find(
          (group) => group.date === invoice.createdAt.toLocaleDateString(),
        ) == null
      ) {
        this.invoicesGroups.push(
          new InvoicesGroup(invoice.createdAt.toLocaleDateString()),
        );
      }
    });
  }

  private groupInvoices(invoices: InvoiceDoc[]): void {
    this.invoicesGroups.forEach((group) => {
      invoices.forEach((invoice) => {
        if (invoice.createdAt.toLocaleDateString() === group.date) {
          switch (this.invoiceType) {
            case Invoice.refill:
              (group.invoices as Refill[]).push(invoice as Refill);
              break;

            case Invoice.return:
            case Invoice.waste:
              (group.invoices as Waste[]).push(invoice as Waste);
              break;

            case Invoice.move:
              (group.invoices as Move[]).push(invoice as Move);
              break;

            case Invoice.order:
              (group.invoices as Order[]).push(invoice as Order);
              break;
          }
        }
      });

      group.invoices.sort(
        (a: InvoiceDoc, b: InvoiceDoc) =>
          b.createdAt.getTime() - a.createdAt.getTime(),
      );
    });
  }

  private loadShiftData(): void {
    this.shiftsService.getShift().subscribe(
      (shift) => {
        if (shift) {
          this.shift = shift;
        }
      },
      (error) => {
        //
      },
    );
  }

  private loadPRroShiftData(): void {
    if (!this.isPRroActive) {
      return;
    }

    this.prroService
      .getZReport()
      .then((zReport) => {
        this.prroZReport = zReport;
      })
      .catch((reason) => {
        //
      });
  }

  private getConfirmSubtitle(invoice: InvoiceDoc): string {
    let operationSum = 0;

    switch (this.invoiceType) {
      case Invoice.waste:
        operationSum = (invoice as Waste).receivedAmount;
        break;

      case Invoice.refill:
        operationSum = (invoice as Refill).paidAmount;
        break;
    }

    if (Math.abs(operationSum) === 0 || this.invoiceType === Invoice.move) {
      return '';
    }

    const operationName =
      this.invoiceType === Invoice.waste ? 'інкасацію' : 'внесення коштів';

    const prroStatus =
      this.isPRroActive && this.prroZReport.opened
        ? ' з використанням ПРРО'
        : '';

    const sumSource =
      this.invoiceType === Invoice.waste ? 'Отримано' : 'Сплачено';

    const sumValue = formatNumber(Math.abs(operationSum), 'uk_UA', '1.2-2');

    return `Буде виконано ${operationName}${prroStatus} на суму, яка вказана у полі "${sumSource}": ${sumValue} ₴<br><br>`;
  }

  providerHeader(): string {
    return this.invoiceType === Invoice.move
      ? 'Отримувач / Постачальник'
      : this.invoiceType === Invoice.waste
      ? 'Назва'
      : 'Постачальник';
  }

  docName(invoice: InvoiceDoc): string {
    return invoice.name.includes('за зміну') ? '' : `(${invoice.name})`;
  }
}
