import { Subcategory } from '../subcategories/subcategory.model';

export class Product extends Subcategory {
  price: number;
  wholesalePrice?: number;
  markupPercent?: number;

  amount: string;
  barcode: string;
  weightProduct: boolean;
  freeCupActive: boolean;
  printLabel: boolean;

  subcategoryId: number;
  subcategoryName: string;

  plu?: string;
  UKTZED?: string;

  excise?: {
    id: number;
    percent: number;
    letter: string;
    needLabel: boolean;
  };

  vat?: {
    id: number;
    percent: number;
    letter: string;
  };

  promoSchedules?: [
    {
      id: number;
      startedAt: Date;
      finishedAt: Date;
      price: number;
    },
  ];

  // App field
  storageUrl?: string;
  labelsCount?: number;
}
