import { Product } from '../../shop/products/product.model';

import { IngredientCategory } from './ingredient-category.model';

export class Ingredient {
  id: number;
  name: string;
  measurement: string;
  barcode: string;
  position: number;

  categoryId: number;
  category: IngredientCategory;

  product?: Product;

  quantity: number;
  costPrice: number;
}
