<ion-header>
  <ion-toolbar>
    <ion-title>{{ productExpanded ? 'Нове найменування' : 'Нова сировина' }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <form #ingredientForm="ngForm">
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="floating">Назва</ion-label>
            <ion-input type="text" name="name" autocomplete="off" [(ngModel)]="ingredient.name" [required]="true"></ion-input>
          </ion-item>
        </ion-col>  
      </ion-row>
  
      <ion-row>
        <ion-col size="8">
          <ion-item>
            <ion-label>Категорія</ion-label>
            <ion-select id="ingredientCategory"
                        name="ingredientCategory" 
                        okText="Вибрати" 
                        cancelText="Скасувати" 
                        [(ngModel)]="ingredient.categoryId"
                        [required]="true">
              <ion-select-option *ngFor="let ingredientCategory of ingredientCategories" [value]="ingredientCategory.id">
                {{ ingredientCategory.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>  
  
        <ion-col size="4">
          <ion-item>
            <ion-label>Од. виміру</ion-label>
            <ion-select id="measurement" 
                        name="measurement"
                        okText="Вибрати" 
                        cancelText="Скасувати" 
                        [(ngModel)]="ingredient.measurement"
                        (ngModelChange)="updateAmountType()"
                        [required]="true">
              <ion-select-option *ngFor="let measurement of measurements" [value]="measurement">
                {{ measurement }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>  
      </ion-row>
  
      <ion-row>
        <ion-col size="4">
          <ion-item>
            <ion-label position="floating">Штрихкод</ion-label>
            <ion-input type="text" name="barcode" [(ngModel)]="ingredient.barcode"></ion-input>
          </ion-item>
        </ion-col>  
  
        <ion-col size="8">
          <ion-item>
            <ion-label>Створити найменування для продажу</ion-label>
            <ion-toggle name="productExpanded" [ngModel]="productExpanded" (ngModelChange)="toggleProductExpanded()"></ion-toggle>
          </ion-item>
        </ion-col>  
      </ion-row>
    </form>

    <form #productForm="ngForm">
      <ion-row *ngIf="productExpanded">
        <ion-col>
          <ion-item>
            <ion-label>Категорія</ion-label>
            <ion-select name="subcategory"
                        okText="Вибрати"
                        cancelText="Скасувати"
                        [(ngModel)]="subcategory"
                        [required]="true"
                        [interfaceOptions]="{ cssClass: 'new-product-select-option' }"
                        (ngModelChange)="categoryChanged()">
              <ng-container *ngFor="let category of categories">
                <ion-select-option [disabled]="true">
                  {{ category.name }}
                </ion-select-option>
                
                <ion-select-option *ngFor="let subcategory of category.subcategories" [value]="subcategory">
                  {{ subcategory.name }}
                </ion-select-option>
              </ng-container>
            </ion-select>
          </ion-item>
        </ion-col>  
      </ion-row>
  
      <ion-row *ngIf="subcategory">
        <ion-col size="4">
          <ion-item>
            <ion-label  position="floating">{{ pricePlaceholder }}</ion-label>
            <ion-input  type="number"
                        name="price"
                        step="0.01"
                        [required]="true"
                        [(ngModel)]="product.price"
                        [min]="MIN_CURRENCY_VALUE.toString()"
                        [bkMin]="MIN_CURRENCY_VALUE"
                        [max]="MAX_CURRENCY_VALUE.toString()"
                        [bkMax]="MAX_CURRENCY_VALUE"></ion-input>
          </ion-item>
        </ion-col>  
  
        <ion-col size="8">
          <ion-item>
            <ion-label>Ваговий товар</ion-label>
            <ion-toggle name="weightProduct" 
                        [(ngModel)]="product.weightProduct"
                        [disabled]="ingredient.measurement === ingredientMeasurements.Unit"></ion-toggle>
          </ion-item>
        </ion-col>  
      </ion-row>
  
      <ion-row *ngIf="subcategory && linkedItems.length">
        <ion-col>
          <ion-note class="table-header">Створити на торгових точках:</ion-note>
  
          <div class="table-data-row" *ngFor="let linkedItem of linkedItems; let i = index">
            <span class="column-selected">
              <ion-checkbox [name]="'selected_' + i" [checked]="linkedItem.selected"></ion-checkbox>
            </span>
  
            <span class="column-name">{{ linkedItem.shopName }}</span>
          </div>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="two-buttons" slot="start" color="tertiary" (click)="cancel()">Скасувати</ion-button>
    <ion-button class="two-buttons" slot="end" color="primary" (click)="save()" [disabled]="!ingredientForm.valid || (productExpanded && !productForm.valid)">Зберегти</ion-button>
  </ion-toolbar>
</ion-footer>
